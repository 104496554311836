var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "knowledge-base-category-page" } }, [
    _c("p", { staticClass: "mb-4" }, [_vm._v(_vm._s(_vm.categorySubtitle))]),
    _c(
      "div",
      { staticClass: "vx-row mt-8 match-height" },
      _vm._l(_vm.sections, function (section) {
        return _c(
          "div",
          {
            key: section.id,
            staticClass: "vx-col w-full sm:w-1/2 md:w-1/3 mb-base",
          },
          [
            _c("vx-card", [
              _c("h4", { staticClass: "mb-4" }, [
                _vm._v(_vm._s(section.title)),
              ]),
              _c(
                "ul",
                { staticClass: "bordered-items" },
                _vm._l(section.questions, function (que) {
                  return _c(
                    "li",
                    { key: que.question, staticClass: "py-2" },
                    [
                      _c("router-link", { attrs: { to: que.answerUrl } }, [
                        _vm._v(_vm._s(que.question)),
                      ]),
                    ],
                    1
                  )
                }),
                0
              ),
            ]),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }